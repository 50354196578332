import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

export const Wrapper = styled('div')(({ theme }) => ({
	width: '100%',
	padding: `${theme.padding.big}px ${theme.padding.small}px`,
	background: theme.colors.darkerBackground,
	borderTop: `1px solid ${theme.colors.border}`,
}));

export const DownloadButton = styled('img')(({ theme }) => ({
	display: 'block',
	marginBottom: theme.padding.normal,
}));

export const BottomNavigationLink = styled(Link)(({ theme }) => ({
	display: 'block',
	fontWeight: 400,
	textDecoration: 'none',
	marginBottom: theme.padding.normal,
}));

export const Title = styled(Typography)(({ theme }) => ({
	fontWeight: '600',
	fontSize: '32px',
	marginBottom: theme.padding.big,
}));

export const FooterNavigationContainer = styled(Grid)(({ theme }) => ({
	paddingBottom: theme.padding.big,
	borderBottom: `1px solid ${theme.colors.border}`,
}));

export const FooterCopyrightContainer = styled(Grid)(({ theme }) => ({
	paddingTop: theme.padding.big,
}));
