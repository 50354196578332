import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export const Wrapper = styled(Box)(({ theme }) => ({
	backgroundColor: theme.colors.background,
	padding: '15px 0',
}));

export const ButtonContainer = styled(Grid)(({ theme }) => ({
	justifyContent: 'flex-start',

	[theme.breakpoints.up('md')]: {
		justifyContent: 'flex-end',
	},
}));
