import React, { useRef } from 'react';
import { ArtworkCard } from '@presentation/components/ArtworkCard';
import AliceCarousel from 'react-alice-carousel';
import { type Theme, useTheme } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import {
	ItemContainer,
	NavigationButtonLeft,
	NavigationButtonRight,
	Wrapper,
} from '@presentation/components/CardsCarusel/styles';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import type { ContentItemWithAuthorModel } from '@domain/models';

export type CardsCarouselProps = {
	items: ContentItemWithAuthorModel[];
};

export const CardsCarousel = (props: CardsCarouselProps) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const carousel = useRef<AliceCarousel>(null);

	const { items } = props;

	const responsive = {
		0: { items: 1 },
		[theme.breakpoints.values.md]: { items: 3 },
		[theme.breakpoints.values.lg]: { items: 4 },
	};

	return (
		<Wrapper maxWidth='xl'>
			<NavigationButtonLeft onClick={(e) => carousel?.current?.slidePrev(e)}>
				<WestIcon />
			</NavigationButtonLeft>
			<NavigationButtonRight onClick={(e) => carousel?.current?.slideNext(e)}>
				<EastIcon />
			</NavigationButtonRight>

			<AliceCarousel
				disableDotsControls
				disableButtonsControls
				paddingRight={isMobile ? 50 : 0}
				ref={carousel}
				infinite
				responsive={responsive}
				items={items.map((content) => (
					<ItemContainer key={content.id}>
						<ArtworkCard
							id={content.id}
							creator={content.author}
							imageName={content.name}
							imageSrc={content.imageSrc}
							onAuthorClick={(event) => {
								event.stopPropagation();
								navigate(`/creator/${content.author?.profileLink}`);
							}}
						/>
					</ItemContainer>
				))}
			/>
		</Wrapper>
	);
};
