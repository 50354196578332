import { Box, Button, Grid, Typography } from '@mui/material';
import theme from '@presentation/styling/theme';
import { Trans } from 'react-i18next';
import type { CreatorModel } from '@domain/models';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

import { AuthorHeaderContainer, AuthorHeaderWrapper, CoverImage, ProfileImage } from './style';
import { InfoBoxProps } from './infoBoxProps';

interface AuthorHeaderProps {
	authorData?: CreatorModel;
}

export const AuthorHeader = ({ authorData }: AuthorHeaderProps) => {
	const { isOpen } = useSmartBanner();

	return (
		<AuthorHeaderWrapper>
			<AuthorHeaderContainer isSmartBannerOpen={isOpen} maxWidth='xl'>
				<CoverImage src={authorData?.coverImageUrl} alt={`cover-image-${authorData?.name}`} />
				<ProfileImage src={authorData?.avatarSrc} alt={`profile-image-${authorData?.name}`} />
				<Grid
					container
					width='100%'
					justifyContent='space-between'
					sx={{
						[theme.breakpoints.down('lg')]: {
							gap: '0px',
						},
						[theme.breakpoints.down('md')]: {
							gap: '16px',
						},
					}}
				>
					<Grid item lg={2} md={3} sm={12}>
						<Box display='flex' flexDirection='column'>
							<Typography
								variant='h4'
								fontWeight='600'
								letterSpacing='0.15px'
								component='h5'
								textAlign='left'
								sx={{ wordBreak: 'break-all' }}
							>
								{authorData?.name}
							</Typography>
						</Box>
					</Grid>
					<Grid
						container
						md={5}
						lg={6}
						sm={12}
						gap='24px'
						justifyContent='center'
						sx={{
							[theme.breakpoints.down('sm')]: {
								gap: '8px',
							},
						}}
					>
						<Grid {...InfoBoxProps} justifyContent='flex-start'>
							<Typography variant='h4' fontWeight='600' letterSpacing='0.15px' component='h5' textAlign='center'>
								{authorData?.puzzlesCount}
							</Typography>
							<Typography color={theme.colors.descriptionText} component='p' textAlign='center'>
								<Trans i18nKey='screens.creator.header.contentItems' />
							</Typography>
						</Grid>
						<Grid {...InfoBoxProps}>
							<Typography variant='h4' fontWeight='600' letterSpacing='0.15px' component='h5' textAlign='center'>
								{authorData?.followersCount}
							</Typography>
							<Typography color={theme.colors.descriptionText} component='p' textAlign='center'>
								<Trans i18nKey='screens.creator.header.followers' />
							</Typography>
						</Grid>
						<Grid {...InfoBoxProps}>
							<Typography variant='h4' fontWeight='600' letterSpacing='0.15px' component='h5' textAlign='center'>
								{authorData?.followingCount}
							</Typography>
							<Typography color={theme.colors.descriptionText} component='p' textAlign='center'>
								<Trans i18nKey='screens.creator.header.following' />
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						lg={3}
						md={3}
						sm={12}
						xs={12}
						display='flex'
						flexDirection='column'
						justifyContent='center'
						alignItems='flex-end'
					>
						<Button
							variant='contained'
							style={{
								textTransform: 'none',
							}}
							size='large'
							color='buttonBlue'
							fullWidth={true}
						>
							<Trans i18nKey='screens.creator.header.viewProfileInTheApp' />
						</Button>
					</Grid>
				</Grid>
			</AuthorHeaderContainer>
		</AuthorHeaderWrapper>
	);
};
