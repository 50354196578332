import type { OrderByDirection } from '@domain/enum/OrderByDirection';
import type { PuzzleSortField } from '@domain/usecases/puzzles/PuzzleSortField';

export const SORT_OPTIONS = [
	{
		value: 'name|asc',
		label: 'screens.category.sortDropdown.nameAtoZ',
	},
	{
		value: 'name|desc',
		label: 'screens.category.sortDropdown.nameZtoA',
	},
	{
		value: 'publishedAt|asc',
		label: 'screens.category.sortDropdown.newest',
	},
	{
		value: 'publishedAt|desc',
		label: 'screens.category.sortDropdown.oldest',
	},
	{
		value: 'plays|asc',
		label: 'screens.category.sortDropdown.playsMost',
	},
	{
		value: 'plays|desc',
		label: 'screens.category.sortDropdown.playsLeast',
	},
	{
		value: 'likes|asc',
		label: 'screens.category.sortDropdown.likesMost',
	},
	{
		value: 'likes|desc',
		label: 'screens.category.sortDropdown.likesLeast',
	},
] as const satisfies Array<{ value: `${PuzzleSortField}|${OrderByDirection}`; label: string }>;
