import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Trans } from 'react-i18next';
import { AuthorInfo } from '@presentation/components/AuthorInfo';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';
import { type Theme, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import type { CreatorModel } from '@domain/models';

import {
	BackgroundImage,
	BannerImage,
	LeftColumn,
	Overlay,
	RightColumn,
	Container,
	Wrapper,
	BannerImageContainer,
	BannerTitle,
} from './styles';

export type ArtworkBannerProps = {
	isLoading: boolean;
	title: string;
	description: string;
	imageSrc: string;
	backgroundImageSrc: string;
	creator: CreatorModel | null;
};
export const ArtworkBanner = ({
	isLoading,
	title,
	description,
	imageSrc,
	backgroundImageSrc,
	creator,
}: ArtworkBannerProps) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const isFullWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	if (isLoading) {
		return (
			<Container container columnSpacing={{ sm: 0, md: 4 }}>
				<Skeleton variant='rounded' height={400} animation='wave' />
			</Container>
		);
	}

	return (
		<Wrapper>
			<Container container columnSpacing={{ sm: 0, md: 4 }}>
				<Overlay />
				<BackgroundImage src={backgroundImageSrc} alt={title} />
				<LeftColumn item xs={12} sm={12} md={6} lg={8} order={{ xs: 2, sm: 2, md: 1 }}>
					<Stack direction='row' spacing={3} alignItems='center' marginBottom={6}>
						<Chip color='warning' label={<Trans i18nKey='general.dailyPuzzle' />} variant='outlined' />
						<FiberManualRecordIcon sx={{ color: theme.colors.cardTextColor, fontSize: 12 }} fontSize='small' />
						<Typography variant='subtitle1White'>{description}</Typography>
						<FiberManualRecordIcon
							sx={{ color: theme.colors.cardTextColor, fontSize: 12, display: { xs: 'none', md: 'block' } }}
							fontSize='small'
						/>
						<Typography sx={{ display: { xs: 'none', md: 'block' } }} variant='subtitle1White'>
							<Trans i18nKey='general.name' />
						</Typography>
					</Stack>

					<AuthorInfo
						creator={creator}
						noShowSubtitle
						color={theme.colors.cardTextColor}
						onClick={() => navigate(`/creator/${creator?.profileLink}`)}
					/>
					<BannerTitle variant='h1' textSize={title.length} marginBottom={6} marginTop={4}>
						{title}
					</BannerTitle>
					<Button fullWidth={isFullWidth} color='buttonBlue' variant='contained'>
						<Trans i18nKey='general.playInApp' />
					</Button>
				</LeftColumn>
				<RightColumn
					item
					xs={12}
					sm={12}
					md={6}
					lg={4}
					display='flex'
					alignItems='center'
					order={{ xs: 1, sm: 1, md: 2 }}
				>
					<BannerImageContainer>
						<BannerImage src={imageSrc} alt={title} />
					</BannerImageContainer>
				</RightColumn>
			</Container>
		</Wrapper>
	);
};
