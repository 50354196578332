import { type ContainerProps, styled } from '@mui/material';
import Container from '@mui/material/Container';

export const MainContent = styled(Container)<ContainerProps & { isSmartBannerOpen: boolean }>(
	({ theme, isSmartBannerOpen }) => ({
		flexGrow: 1,
		padding: '24px',
		minHeight: `calc(100vh - ${isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.xxl : theme.sizes.header.xxl}px)`,
		overflowY: 'auto',
		overflowX: 'hidden',
		marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.xxl : theme.sizes.header.xxl,

		[theme.breakpoints.down('lg')]: {
			marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.lg : theme.sizes.header.lg,
			minHeight: `calc(100vh - ${isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.lg : theme.sizes.header.lg}px)`,
		},
		[theme.breakpoints.down('md')]: {
			marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.md : theme.sizes.header.md,
			minHeight: `calc(100vh - ${isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.md : theme.sizes.header.md}px)`,
		},
	})
);

export const ListContent = styled(Container)<ContainerProps & { isSmartBannerOpen: boolean }>(
	({ theme, isSmartBannerOpen }) => ({
		flexGrow: 1,
		padding: 0,
		minHeight: `calc(100vh - ${isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.xxl : theme.sizes.header.xxl}px)`,
		overflowY: 'auto',
		overflowX: 'hidden',

		[theme.breakpoints.up('lg')]: {
			padding: '0 24px 24px',
		},

		[theme.breakpoints.down('lg')]: {
			minHeight: `calc(100vh - ${isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.lg : theme.sizes.header.lg}px)`,
			padding: 0,
		},
		[theme.breakpoints.down('md')]: {
			minHeight: `calc(100vh - ${isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.md : theme.sizes.header.md}px)`,
		},
	})
);

export const ContentContainer = styled(ListContent)<ContainerProps & { isSmartBannerOpen: boolean }>(
	({ theme, isSmartBannerOpen }) => ({
		padding: '56px 144px',
		marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.xxl : theme.sizes.header.xxl,

		[theme.breakpoints.down('lg')]: {
			marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.lg : theme.sizes.header.lg,
			padding: '16px',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.md : theme.sizes.header.md,
			padding: '16px',
		},
	})
);
