import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';
import { Trans, useTranslation } from 'react-i18next';

import { Wrapper, Container } from './styles';

export type ListPageHeaderBreadcrumb = {
	label: string;
	url?: string;
};

export type ListPageHeaderProps = {
	breadcrumbs?: ListPageHeaderBreadcrumb[];
	title: string;
	count?: number;
};
export const ListPageHeader = (props: ListPageHeaderProps) => {
	const { breadcrumbs, title, count } = props;

	const { isOpen } = useSmartBanner();
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Wrapper isSmartBannerOpen={isOpen}>
			<Container maxWidth='xl'>
				<Box paddingLeft={0}>
					{breadcrumbs && breadcrumbs?.length > 0 && (
						<Breadcrumbs>
							{breadcrumbs.map((item) =>
								item.url ? (
									<Link underline='hover' color={theme.colors.brand} href={item.url} key={item.label}>
										<Trans i18nKey={item.label} />
									</Link>
								) : (
									<Typography key={item.label}>
										<Trans i18nKey={item.label} />
									</Typography>
								)
							)}
						</Breadcrumbs>
					)}
				</Box>
				<Typography
					fontSize={40}
					fontWeight={700}
					color={theme.colors.text}
					style={{ marginTop: 20, marginBottom: 20, paddingLeft: 0 }}
				>
					<Trans i18nKey={title} />
				</Typography>
				<Typography fontSize={14} color={theme.colors.text} style={{ paddingLeft: 0 }}>
					{`${count} ${t('components.listPageHeader.itemsCount')}`}
				</Typography>
			</Container>
		</Wrapper>
	);
};
