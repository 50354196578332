import { styled, Box } from '@mui/material';

export const Wrapper = styled(Box)<{ backgroundImage?: string }>(({ theme, backgroundImage }) => ({
	backgroundColor: theme.colors.background,
	borderRadius: 16,
	position: 'relative',
	border: `1px solid ${theme.colors.border}`,
	minHeight: 100,
	backgroundImage: backgroundImage ? `url("${backgroundImage}")` : undefined,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundSize: 'cover',
	cursor: 'pointer',
	[theme.breakpoints.up('md')]: {
		minHeight: 280,
	},
}));

export const TextWrapper = styled(Box)(() => ({
	position: 'absolute',
	bottom: 20,
	left: 30,
	cursor: 'pointer',
}));
