import { type BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)<BoxProps & { bgimage?: string }>(({ theme, bgimage }) => ({
	position: 'relative',
	width: '100%',
	backgroundColor: theme.colors.background,
	aspectRatio: '1 / 1.4',
	padding: '15px',
	margin: `${theme.spacing(2)} auto ${theme.spacing(2)}`,
	backgroundImage: bgimage ? `url("${bgimage}")` : undefined,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundSize: 'cover',
	cursor: 'pointer',

	[theme.breakpoints.up('sm')]: {
		aspectRatio: '1 / 1',
	},
	[theme.breakpoints.up('md')]: {
		aspectRatio: '1 / 1.4',
		maxWidth: '460px',
		padding: '25px',
		margin: 0,
	},
}));

export const Overlay = styled('div')(() => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	borderRadius: '16px',
	background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%);',
}));
