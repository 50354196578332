import React from 'react';
import { type AppBarProps, type ButtonProps, type MenuItemProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';

export const Wrapper = styled(AppBar)<AppBarProps & { isSmartBannerOpen: boolean }>(({ theme, isSmartBannerOpen }) => ({
	display: 'flex',
	justifyContent: 'center',
	height: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.xxl : theme.sizes.header.xxl,
	boxShadow: 'none',
	borderBottom: `1px solid ${theme.colors.border}`,

	[theme.breakpoints.down('lg')]: {
		height: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.lg : theme.sizes.header.lg,
	},
	[theme.breakpoints.down('md')]: {
		height: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.md : theme.sizes.header.md,
	},
}));

export const HeaderContainer = styled(Container)(({ theme }) => ({
	padding: 0,

	[theme.breakpoints.up('lg')]: {
		padding: '0 24px',
	},

	[theme.breakpoints.down('lg')]: {
		padding: 0,
	},
}));

export const HeaderToolbar = styled(Toolbar)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
}));

export const MenuButton = styled(Button)<ButtonProps & { component?: React.ElementType; to?: string }>(({ theme }) => ({
	marginLeft: theme.margin.normal,
	textTransform: 'none',
}));

export const DownloadButtonMobile = styled(MenuButton)<ButtonProps & { component?: React.ElementType; to?: string }>(
	() => ({
		marginLeft: 0,
	})
);

export const MobileMenuItem = styled(MenuItem)<MenuItemProps & { component?: React.ElementType; to?: string }>(() => ({
	margin: 0,
	padding: '6px 0',
}));

export const Logo = styled('img')(({ theme }) => ({
	marginTop: theme.spacing(1),
}));
