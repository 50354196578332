import { styled } from '@mui/material';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';

export const Wrapper = styled(Container)(({ theme }) => ({
	position: 'relative',
	padding: 0,

	[theme.breakpoints.up('sm')]: {
		paddingRight: 0,
		paddingLeft: 0,
	},
}));

export const ItemContainer = styled('div')(({ theme }) => ({
	margin: `0 ${theme.padding.small}px`,
}));

export const NavigationButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	top: '45%',
	backgroundColor: '#FFF',
	zIndex: theme.zIndex.appBar,
	boxShadow: theme.shadow.iconButton,
}));

export const NavigationButtonLeft = styled(NavigationButton)(() => ({
	left: `-20px`,
}));

export const NavigationButtonRight = styled(NavigationButton)(() => ({
	right: `-20px`,
}));
