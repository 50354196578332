import React, { useEffect } from 'react';
import { CreatorSection } from '@presentation/components/CreatorSection';
import { ArtworkCard } from '@presentation/components/ArtworkCard';
import Grid from '@mui/material/Grid';
import type { CreatorsService } from '@domain/usecases/creators/CreatorsService';
import { ListPageHeader } from '@presentation/components/ListPageHeader';
import { ListContent } from '@presentation/layouts/styles';
import InfiniteScroll from 'react-infinite-scroller';
import { ListHeader } from '@presentation/components/ListHeader';
import { SORT_OPTIONS } from '@presentation/pages/Creators/constants';
import { useGetCreators } from '@presentation/pages/Creators/hooks/useGetCreators';
import type { CreatorSortField } from '@domain/usecases/creators/CreatorSortField';
import { SectionLoader } from '@presentation/components/Loader/SectionLoader';
import { useNavigate } from 'react-router-dom';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

const CreatorsPage = ({ creatorsService }: { creatorsService: CreatorsService }) => {
	const { isOpen, generateLink } = useSmartBanner();
	const navigate = useNavigate();
	const { creators, sort, hasMore, handleSort, fetchContent, count } = useGetCreators(creatorsService);

	useEffect(() => {
		generateLink('HOME');
	}, [generateLink]);

	return (
		<>
			<ListPageHeader
				breadcrumbs={[
					{
						label: 'screens.home.title',
						url: '/',
					},
					{
						label: 'screens.creators.title',
					},
				]}
				title='screens.creators.title'
				count={count}
			/>
			<ListContent isSmartBannerOpen={isOpen} maxWidth='xl'>
				<>
					<ListHeader
						sortOptions={SORT_OPTIONS}
						sortBy={`${sort.field}|${sort.direction}`}
						onSortChange={(orderBy, orderDirection) => handleSort(orderBy as CreatorSortField, orderDirection)}
					/>
					<InfiniteScroll pageStart={0} loadMore={() => fetchContent()} hasMore={hasMore} loader={<SectionLoader />}>
						{creators.map((creator) => (
							<div key={creator.id}>
								<CreatorSection creator={creator} content={creator.puzzles[0]} viewInAppLink='/'>
									<>
										<Grid container spacing={2} sx={{ flexGrow: 0, display: { xs: 'none', lg: 'flex' } }}>
											{creator.puzzles.map((puzzle: any) => (
												<Grid item xs={12} md={3} key={puzzle.id}>
													<ArtworkCard
														id={puzzle.id}
														creator={creator}
														imageName={puzzle.name}
														imageSrc={puzzle.imageSrc}
														onAuthorClick={(event) => {
															event.stopPropagation();
															navigate(`/creator/${creator.profileLink}`);
														}}
													/>
												</Grid>
											))}
										</Grid>
										<Grid container spacing={2} sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex', lg: 'none' } }}>
											{creator.puzzles.slice(0, 3).map((puzzle: any) => (
												<Grid item xs={4} key={puzzle.id}>
													<ArtworkCard
														id={puzzle.id}
														creator={creator}
														imageName={creator.name}
														imageSrc={puzzle.imageSrc}
														onAuthorClick={(event) => {
															event.stopPropagation();
															navigate(`/creator/${creator.profileLink}`);
														}}
													/>
												</Grid>
											))}
										</Grid>
									</>
								</CreatorSection>
							</div>
						))}
					</InfiniteScroll>
				</>
			</ListContent>
		</>
	);
};

export default CreatorsPage;
