import { createContext, type ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useModal } from '@presentation/context/ModalContextProvider';
import ContentDetails from '@presentation/modals/ContentDetails';
import { makeContentService } from '@main/factories/makeContentService';
import { ROUTES } from '@main/routes/routes';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

type ContentDetailsModalContext = {
	showContentDetails: (id: string, type: string) => void;
	closeContentDetails: () => void;
};

const initialValue: ContentDetailsModalContext = {
	showContentDetails: () => {},
	closeContentDetails: () => {},
};

export const ContentDetailsModalContext = createContext(initialValue);

const CONTENT_DETAILS_MODAL_ID = 'content-details-modal-id';

export const ContentDetailsModalContextProvider = ({ children }: { children: ReactNode }) => {
	const { generateLink } = useSmartBanner();
	const { openModal, closeModal } = useModal();

	const [prevPath, setPrevPath] = useState<string>('/');
	const [content, setContent] = useState<{ id: string; type: string } | null>(null);

	const showContentDetails = useCallback<ContentDetailsModalContext['showContentDetails']>(
		async (id, type) => {
			const currentPath = window.location.pathname;

			setPrevPath(currentPath.includes(ROUTES.PUZZLE) ? '/' : currentPath);

			history.pushState({}, '', `${ROUTES.PUZZLE}/${id}`);

			generateLink('PUZZLE', id);

			setContent({ id, type });
		},
		[setContent, generateLink]
	);

	const closeContentDetails = useCallback<ContentDetailsModalContext['closeContentDetails']>(async () => {
		closeModal(CONTENT_DETAILS_MODAL_ID);

		history.pushState({}, '', prevPath);

		setContent(null);
		generateLink('HOME');
	}, [setContent, prevPath, closeModal, generateLink]);

	useEffect(() => {
		if (content) {
			openModal(CONTENT_DETAILS_MODAL_ID, ContentDetails, {
				id: content.id,
				type: content.type,
				open: true,
				contentService: makeContentService(),
				onClose: closeContentDetails,
			});
		}
	}, [content, openModal, closeContentDetails]);

	return (
		<ContentDetailsModalContext.Provider value={{ showContentDetails, closeContentDetails }}>
			{children}
		</ContentDetailsModalContext.Provider>
	);
};

export const useContentDetailsModal = () => {
	const context = useContext(ContentDetailsModalContext);

	if (!context) {
		throw new Error('Cannot use ContentDetailsModalContext outside provider');
	}

	return context;
};
