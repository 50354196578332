import { Box, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Trans } from 'react-i18next';

import { ButtonContainer, Wrapper } from './styles';

export type ContentSectionProps = {
	title: string;
	exploreAllLink?: string;
	children: React.ReactElement;
};
export const ContentSection = (props: ContentSectionProps) => {
	const { title, exploreAllLink, children } = props;

	return (
		<Wrapper>
			<Grid
				container
				display='flex'
				alignItems='center'
				justifyContent='space-between'
				marginBottom={3}
				paddingLeft={1}
				paddingRight={1}
			>
				<Grid item xs={12} md={6}>
					<Typography variant='h2'>{title}</Typography>
				</Grid>
				{exploreAllLink && (
					<ButtonContainer item xs={12} md={6} display='flex' alignItems='center'>
						<Link href={exploreAllLink}>
							<Typography variant='link'>
								<Trans i18nKey='components.contentSection.exploreAll' />
							</Typography>
						</Link>
					</ButtonContainer>
				)}
			</Grid>
			<Box display='flex'>{children}</Box>
		</Wrapper>
	);
};
