import React, { createContext, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { RemoteConfigRepository } from '@infrastructure/firebase/remoteConfig/RemoteConfigRepository';

const RemoteConfigContext = createContext<undefined>(undefined);

interface RemoteConfigProviderProps {
	children: React.ReactNode;
}

export const RemoteConfigProvider = ({ children }: RemoteConfigProviderProps) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		RemoteConfigRepository.init().then(() => {
			setIsLoading(false);
		});
	}, []);

	if (isLoading) {
		return <CircularProgress />;
	}

	return <RemoteConfigContext.Provider value={undefined}>{children}</RemoteConfigContext.Provider>;
};
