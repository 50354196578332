import React, { useEffect } from 'react';
import { ContentDetailsModal } from '@presentation/components/ContentDetailsModal';
import type { ContentService } from '@domain/usecases/content/ContentService';
import Modal from '@mui/material/Modal';
import { useGetContentDetails } from '@presentation/modals/ContentDetails/hooks';
import { Loader } from '@presentation/components/Loader';

const ContentDetails = ({
	id,
	open,
	onClose,
	contentService,
}: {
	id: string;
	open: boolean;
	onClose: () => void;
	contentService: ContentService;
}) => {
	const { content, appLink, isLoading } = useGetContentDetails(id, contentService);

	useEffect(() => {
		if (!isLoading && content === null) {
			onClose();
		}
	}, [isLoading, content, onClose]);

	return (
		<Modal open={open} onClose={onClose}>
			<>
				{isLoading && <Loader />}
				{content && <ContentDetailsModal onClose={onClose} content={content} linkInApp={appLink} />}
			</>
		</Modal>
	);
};

export default ContentDetails;
