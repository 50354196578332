import React from 'react';
import ReactDOM from 'react-dom/client';

import './config/sentry';
import './config/firebase';
import App from './main/App.jsx';
import './index.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
