import { ListPageHeader } from '@presentation/components/ListPageHeader';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { ListHeader } from '@presentation/components/ListHeader';
import { ListContent } from '@presentation/layouts/styles';
import type { CollectionsService } from '@domain/usecases/collections/CollectionsService';
import { CollectionSortField } from '@domain/usecases/collections/CollectionSortField';
import { RowLoader } from '@presentation/components/Loader';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

import { SORT_OPTIONS } from './constants';
import { Card } from './components';
import { useHomePageCategories } from './hooks';

const CategoriesPage = ({ collectionsService }: { collectionsService: CollectionsService }) => {
	const { isOpen, generateLink } = useSmartBanner();
	const navigate = useNavigate();
	const { data, handleSort, fetchCategories, hasMore, count, sort } = useHomePageCategories(collectionsService);

	useEffect(() => {
		generateLink('HOME');
	}, [generateLink]);

	return (
		<>
			<ListPageHeader
				breadcrumbs={[
					{
						label: 'screens.home.title',
						url: '/',
					},
					{
						label: 'screens.categories.title',
					},
				]}
				title='screens.categories.title'
				count={count}
			/>
			<ListContent isSmartBannerOpen={isOpen} maxWidth='xl'>
				<ListHeader
					sortOptions={SORT_OPTIONS}
					sortBy={`${sort.field}|${sort.direction}`}
					onSortChange={(orderBy, orderDirection) => handleSort(orderBy as CollectionSortField, orderDirection)}
				/>
				{!data.length && <RowLoader mobileThin />}

				<InfiniteScroll
					pageStart={0}
					loadMore={() => fetchCategories()}
					hasMore={hasMore}
					loader={<RowLoader mobileThin />}
				>
					<Grid container spacing={2} paddingLeft={1} paddingRight={1}>
						{data?.length > 0 &&
							data.map((item: any) => (
								<Grid item xs={12} md={3} key={item.id}>
									<Card
										key={item.id}
										image={item?.imageSrc}
										itemsNumber={item?.numberOfPuzzles}
										name={item?.name}
										onClick={() => navigate(`/category/${item.id}`)}
									/>
								</Grid>
							))}
					</Grid>
				</InfiniteScroll>
			</ListContent>
		</>
	);
};

export default CategoriesPage;
