import React, { type FC } from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
	BottomNavigationLink,
	DownloadButton,
	FooterCopyrightContainer,
	FooterNavigationContainer,
	Title,
	Wrapper,
} from '@presentation/components/Footer/styles';
import downloadGoogle from '@presentation/assets/download-google.png';
import downloadApple from '@presentation/assets/download-apple.png';
import { Trans, useTranslation } from 'react-i18next';
import { LINK_APPLE_STORE, LINK_PLAY_STORE } from '@main/config/links';

export const Footer: FC = () => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<Container maxWidth='xl'>
				<FooterNavigationContainer container spacing={0} paddingRight={2} paddingLeft={2}>
					<Grid item md={4} xs={12}>
						<Title variant='h4'>
							<Trans i18nKey='general.footer.downloadApp' />
						</Title>
						<Link href={LINK_PLAY_STORE} target='_blank'>
							<DownloadButton src={downloadGoogle} alt={t('general.downloadPlayStore')} />
						</Link>
						<Link href={LINK_APPLE_STORE} target='_blank'>
							<DownloadButton src={downloadApple} alt={t('general.downloadAppStore')} />
						</Link>
					</Grid>
					<Grid item md={4} xs={12}>
						<Title variant='h4'>
							<Trans i18nKey='general.footer.navigation' />
						</Title>
						<BottomNavigationLink href='/'>
							<Trans i18nKey='general.menu.homepage' />
						</BottomNavigationLink>
						<BottomNavigationLink href='/categories'>
							<Trans i18nKey='general.menu.categories' />
						</BottomNavigationLink>
						<BottomNavigationLink href='/creators'>
							<Trans i18nKey='general.menu.creators' />
						</BottomNavigationLink>
					</Grid>
					<Grid item md={4} xs={12}>
						<Title variant='h4'>
							{' '}
							<Trans i18nKey='general.footer.legal' />
						</Title>
						<BottomNavigationLink href='https://www.gotiva.global/terms-and-conditions-for-creators'>
							<Trans i18nKey='general.menu.termsAndConditions' />
						</BottomNavigationLink>
						<BottomNavigationLink href='https://www.gotiva.global/privacy-policy'>
							<Trans i18nKey='general.menu.privacyPolicy' />
						</BottomNavigationLink>
						<BottomNavigationLink href='https://www.gotiva.global'>
							<Trans i18nKey='general.menu.support' />
						</BottomNavigationLink>
					</Grid>
				</FooterNavigationContainer>
				<FooterCopyrightContainer container spacing={0} paddingLeft={2}>
					<Grid item sm={12}>
						<Typography>
							{' '}
							<Trans i18nKey='general.copyright' />{' '}
						</Typography>
					</Grid>
				</FooterCopyrightContainer>
			</Container>
		</Wrapper>
	);
};
