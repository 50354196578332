type LinkTypes = keyof typeof LinkGeneratorService.LINK_TYPES;

export class LinkGeneratorService {
	static readonly LINK_TYPES = {
		PUZZLE: 'PUZZLE',
		CATEGORY: 'CATEGORY',
		ARTIST: 'ARTIST',
		HOME: 'HOME',
	} as const;

	// VITE_ENVIRONMENT
	static readonly LINK_TEMPLATES = {
		development: {
			[LinkGeneratorService.LINK_TYPES.PUZZLE]:
				'https://jpu.sng.link/A64mf/mki5?_dl=puzzle&_smtype=3&_p={passthroughId}',
			[LinkGeneratorService.LINK_TYPES.CATEGORY]:
				'https://jpu.sng.link/A64mf/eopk?_dl=category&_smtype=3&_p={passthroughId}',
			[LinkGeneratorService.LINK_TYPES.ARTIST]:
				'https://jpu.sng.link/A64mf/8kb6?_dl=artist&_smtype=3&_p={passthroughId}',
			[LinkGeneratorService.LINK_TYPES.HOME]: 'https://jpu.sng.link/A64mf/c85b?_dl=home&_smtype=3',
		},
		production: {
			[LinkGeneratorService.LINK_TYPES.PUZZLE]:
				'https://jpu.sng.link/Ak2o2/zwr8?_dl=puzzle&_smtype=3&_p={passthroughId}',
			[LinkGeneratorService.LINK_TYPES.CATEGORY]:
				'https://jpu.sng.link/Ak2o2/hur3?_dl=category&_smtype=3&_p={passthroughId}',
			[LinkGeneratorService.LINK_TYPES.ARTIST]:
				'https://jpu.sng.link/Ak2o2/1lhl?_dl=artist&_smtype=3&_p={passthroughId}',
			[LinkGeneratorService.LINK_TYPES.HOME]: 'https://jpu.sng.link/Ak2o2/j1pg?_dl=home&_smtype=3',
		},
	} as const satisfies { [environment: string]: { [linkType in LinkTypes]: string } };

	constructor() {}

	getSingularLink(type: keyof typeof LinkGeneratorService.LINK_TYPES, id?: string) {
		const environment = import.meta.env.VITE_ENVIRONMENT as keyof typeof LinkGeneratorService.LINK_TEMPLATES;

		if (!Object.hasOwn(LinkGeneratorService.LINK_TEMPLATES, environment)) {
			console.warn(`[BUG] Environment ${environment} not supported for link generator`);
			return '';
		}

		const linkTemplate = LinkGeneratorService.LINK_TEMPLATES[environment][type];
		return linkTemplate.replace('{passthroughId}', id || '');
	}
}
