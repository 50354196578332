import { Typography, useTheme } from '@mui/material';

import { TextWrapper, Wrapper } from './styles';

export type CardProps = {
	image?: string;
	itemsNumber?: number;
	name: string;
	onClick?: () => void;
	nameFontSize?: number;
	className?: string;
};

export const Card = (props: CardProps) => {
	const theme = useTheme();

	const { image, itemsNumber, name, onClick, nameFontSize } = props;

	return (
		<Wrapper className={props.className} backgroundImage={image} onClick={onClick}>
			<TextWrapper>
				<Typography
					fontSize={nameFontSize ? nameFontSize : 20}
					fontWeight={700}
					color={image ? theme.colors.background : theme.colors.text}
				>
					{name}
				</Typography>
				<Typography fontSize={14} fontWeight={400} color={image ? theme.colors.background : theme.colors.text}>
					{itemsNumber ?? 0} items
				</Typography>
			</TextWrapper>
		</Wrapper>
	);
};
