import type { ICreatorsRepository } from '@domain/usecases/creators/CreatorsRepository';
import type { IPuzzlesRepository } from '@domain/usecases/puzzles/PuzzlesRepository';
import type { ContentItemModel, CreatorModel } from '@domain/models';
import type { ListOrder } from '@domain/types/ListOrder';
import type { PuzzleSortField } from '@domain/usecases/puzzles/PuzzleSortField';
import type { CreatorSortField } from '@domain/usecases/creators/CreatorSortField';
import { CreatorStatus } from '@domain/enum/CreatorStatus';

export class CreatorsService {
	private DEFAULT_ITEMS_LIMIT = 5;

	constructor(
		private readonly creatorsRepository: ICreatorsRepository,
		private readonly puzzlesRepository: IPuzzlesRepository
	) {}

	async getCreator(options: { idOrProfileLink: string }): Promise<CreatorModel | null> {
		const parsedIdentifier = options.idOrProfileLink.split('-')[0];

		const result = await this.creatorsRepository.getCreatorById(parsedIdentifier);
		if (!result) {
			return this.creatorsRepository.getCreatorByProfileLink(parsedIdentifier);
		}

		return result;
	}

	async getCreatorIfApproved(options: { idOrProfileLink: string }) {
		const creator = await this.getCreator(options);

		if (creator?.status === CreatorStatus.rejected) return null;

		return creator;
	}

	async getContentByCreator(
		creatorId: string,
		options: { last?: string; order?: ListOrder<PuzzleSortField> }
	): Promise<{ items: ContentItemModel[]; count: number; hasMore: boolean }> {
		const [items, count] = await Promise.all([
			this.puzzlesRepository.getPuzzlesByPublisher(creatorId, { limit: 12, ...options }),
			this.puzzlesRepository.countPuzzlesByPublisher(creatorId),
		]);

		return {
			items,
			count,
			hasMore: items.length == 12,
		};
	}

	async getNextCreatorsPage(options: {
		last?: any;
		order?: ListOrder<CreatorSortField>;
	}): Promise<{ items: any[]; count: number; hasMore: boolean }> {
		const creators = await this.creatorsRepository.getCreators({
			limit: this.DEFAULT_ITEMS_LIMIT,
			...options,
		});
		const count = await this.creatorsRepository.count();

		return {
			count: count,
			hasMore: count >= this.DEFAULT_ITEMS_LIMIT,
			items: await Promise.all(
				creators.map(async (creator) => ({
					...creator,
					puzzles: await this.puzzlesRepository.getPuzzlesByPublisher(creator.id, { limit: 4 }),
				}))
			),
		};
	}
}
