import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { TypographyProps } from '@mui/material/Typography/Typography';

export const Wrapper = styled('div')(({ theme }) => ({
	padding: `0 ${theme.padding.small}px`,
}));

export const Container = styled(Grid)(({ theme }) => ({
	position: 'relative',
	border: `1px solid ${theme.colors.border}`,
	borderRadius: '32px',
	backgroundColor: theme.colors.darkerBackground,
	margin: `${theme.spacing(1)} 0`,
	width: '100%',
	padding: 0,
	overflow: 'hidden',

	[theme.breakpoints.up('md')]: {
		width: '100%',
		padding: theme.spacing(4),
		margin: `${theme.spacing(1)} 0`,
	},

	[theme.breakpoints.up('lg')]: {
		padding: theme.spacing(4),
		margin: `${theme.spacing(1)} 0 ${theme.spacing(4)}`,
	},
}));

export const LeftColumn = styled(Grid)(({ theme }) => ({
	padding: theme.spacing(3),
	zIndex: theme.zIndex.appBar,

	[theme.breakpoints.up('md')]: {
		padding: 0,
	},
}));

export const RightColumn = styled(Grid)(({ theme }) => ({
	padding: theme.spacing(3),
	zIndex: theme.zIndex.appBar,

	[theme.breakpoints.up('md')]: {
		padding: 0,
	},
}));

export const BackgroundImage = styled('img')(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	objectFit: 'cover',
	objectPosition: 'center',
	opacity: 0.7,
	zIndex: theme.zIndex.fab,
}));

const OneLineBannerTitleSize = 48;
export const BannerTitle = styled(Typography)<TypographyProps & { textSize: number }>(({ theme, textSize }) => ({
	fontSize: textSize > OneLineBannerTitleSize ? '30px' : '40px',

	[theme.breakpoints.up('sm')]: {
		fontSize: textSize > OneLineBannerTitleSize ? '32px' : '40px',
	},

	[theme.breakpoints.up('md')]: {
		fontSize: textSize > OneLineBannerTitleSize ? '32px' : '56px',
	},

	[theme.breakpoints.up('lg')]: {
		fontSize: textSize > OneLineBannerTitleSize ? '40px' : '56px',
	},
}));

export const BannerImageContainer = styled('div')(() => ({
	width: '100%',
}));

export const BannerImage = styled('img')(({ theme }) => ({
	width: '100%',
	margin: '0 auto',
	display: 'block',
	maxWidth: `${theme.sizes.bannerImage.maxWidth}px`,
	aspectRatio: '1/1',
	objectFit: 'fill',
	marginLeft: 'auto',
	borderRadius: '32px',
	zIndex: theme.zIndex.appBar,

	[theme.breakpoints.up('md')]: {
		float: 'right',
	},
}));

export const Overlay = styled('div')(() => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	background:
		'linear-gradient(0deg, #000000, #000000), linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 45.63%)',
}));
