import { Box, type BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import MUIContainer from '@mui/material/Container';

export const Wrapper = styled(Box)<BoxProps & { isSmartBannerOpen: boolean }>(({ theme, isSmartBannerOpen }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.xxl : theme.sizes.header.xxl,
	backgroundColor: theme.colors.darkerBackground,

	[theme.breakpoints.down('lg')]: {
		marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.lg : theme.sizes.header.lg,
		padding: `${theme.spacing(4)} 0`,
	},

	[theme.breakpoints.up('lg')]: {
		padding: `${theme.spacing(5)} 0`,
	},

	[theme.breakpoints.down('md')]: {
		marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.md : theme.sizes.header.md,
	},
}));

export const Container = styled(MUIContainer)(({ theme }) => ({
	padding: `0 ${theme.spacing(2)}`,

	[theme.breakpoints.up('lg')]: {
		padding: `0 ${theme.spacing(4)}`,
	},

	[theme.breakpoints.down('lg')]: {
		padding: `0 ${theme.spacing(2)}`,
	},
}));
