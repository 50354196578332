import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material/styles';

export const SectionLoader = () => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

	return (
		<Grid
			container
			spacing={0}
			alignContent='center'
			justifyContent='center'
			paddingTop={3}
			paddingRight={1}
			paddingLeft={1}
		>
			<Grid item xs={12}>
				<Skeleton variant='rounded' height={72} />
			</Grid>
			<Grid container spacing={3} alignContent='center' justifyContent='center' paddingTop={3}>
				<Grid item xs={9} md={4} lg={3}>
					<Skeleton variant='rounded' height={400} />
				</Grid>
				{!isMobile && (
					<Grid item xs={9} md={4} lg={3}>
						<Skeleton variant='rounded' height={400} />
					</Grid>
				)}
				{!isMobile && (
					<Grid item xs={9} md={4} lg={3}>
						<Skeleton variant='rounded' height={400} />
					</Grid>
				)}
				{!isTablet && (
					<Grid item xs={9} md={4} lg={3}>
						<Skeleton variant='rounded' height={400} />
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};
