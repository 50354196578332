import React from 'react';
import type { ContentItemWithAuthorModel } from '@domain/models';
import { RowLoader } from '@presentation/components/Loader';
import InfiniteScroll from 'react-infinite-scroller';
import { Grid } from '@mui/material';
import { ArtworkCard } from '@presentation/components/ArtworkCard';
import { useNavigate } from 'react-router-dom';

export type ArtworkInfiniteScrollListProps = {
	data: ContentItemWithAuthorModel[];
	hasMore: boolean;
	loadMore: () => void;
};

export const ArtworkInfiniteScrollList = (props: ArtworkInfiniteScrollListProps) => {
	const navigate = useNavigate();
	const { data, hasMore, loadMore } = props;

	if (!data.length) {
		return <RowLoader />;
	}

	return (
		<InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore} loader={<RowLoader />}>
			<Grid container spacing={2} paddingLeft={1} paddingRight={1}>
				{data?.length > 0 &&
					data.map((item) => (
						<Grid item xs={12} sm={4} md={3} key={item.id}>
							<ArtworkCard
								id={item.id}
								imageSrc={item.imageSrc}
								imageName={item.name}
								creator={item.author}
								onAuthorClick={(event) => {
									event.stopPropagation();

									if (item.author?.profileLink) {
										navigate(`/creator/${item.author?.profileLink}`);
									}
								}}
							/>
						</Grid>
					))}
			</Grid>
		</InfiniteScroll>
	);
};
