import React, { useEffect } from 'react';
import { ListPageHeader } from '@presentation/components/ListPageHeader';
import { ListHeader } from '@presentation/components/ListHeader';
import { Loader } from '@presentation/components/Loader';
import { ContentContainer, ListContent } from '@presentation/layouts/styles';
import { SORT_OPTIONS } from '@presentation/pages/Category/constants';
import type { CollectionsService } from '@domain/usecases/collections/CollectionsService';
import { useCategoryContents } from '@presentation/pages/Category/hooks/useCategoryContents';
import type { PuzzleSortField } from '@domain/usecases/puzzles/PuzzleSortField';
import { ArtworkInfiniteScrollList } from '@presentation/components/ArtworkInfiniteScrollList/ArtworkInfiniteScrollList';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

import NotFound from '../NotFound';

import { useGetCategory } from './hooks';

const CategoryPage = ({ collectionsService }: { collectionsService: CollectionsService }) => {
	const { isOpen, generateLink } = useSmartBanner();
	const { id, category, isLoading } = useGetCategory(collectionsService);
	const { data, fetchContent, hasMore, handleSort, sort } = useCategoryContents(category?.id, collectionsService);

	useEffect(() => {
		if (id) {
			generateLink('CATEGORY', id);
		}
	}, [id, generateLink]);

	if (isLoading) {
		return (
			<ContentContainer isSmartBannerOpen={isOpen} maxWidth='xl'>
				<Loader />
			</ContentContainer>
		);
	}

	/* TODO Move this check on backend side */
	if (!category) {
		return (
			<ContentContainer isSmartBannerOpen={isOpen} maxWidth='xl'>
				<NotFound />
			</ContentContainer>
		);
	}

	return (
		<>
			<ListPageHeader
				breadcrumbs={[
					{
						label: 'screens.home.title',
						url: '/',
					},
					{
						label: 'screens.categories.title',
						url: '/categories',
					},
					{
						label: category.name,
					},
				]}
				title={category.name}
				count={category.numberOfPuzzles}
			/>
			<ListContent isSmartBannerOpen={isOpen} maxWidth='xl'>
				<ListHeader
					sortOptions={SORT_OPTIONS}
					sortBy={`${sort.field}|${sort.direction}`}
					onSortChange={(orderBy, orderDirection) => handleSort(orderBy as PuzzleSortField, orderDirection)}
				/>
				<ArtworkInfiniteScrollList data={data} hasMore={hasMore} loadMore={() => fetchContent()} />
			</ListContent>
		</>
	);
};

export default CategoryPage;
