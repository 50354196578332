import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { LinkGeneratorService } from '@domain/services/LinkGeneratorService';

type SmartBannerModalContext = {
	isOpen: boolean;
	link: string;
	generateLink: (type: keyof typeof LinkGeneratorService.LINK_TYPES, id?: string) => void;
	closeBanner: () => void;
};

const initialValue: SmartBannerModalContext = {
	isOpen: false,
	link: '',
	generateLink: () => {},
	closeBanner: () => {},
};

const SMART_BANNER_LOCALSTORAGE_KEY = 'smart-banner-closed';
export const SmartBannerContext = createContext(initialValue);

export const SmartBannerContextProvider = ({ children }: { children: ReactNode }) => {
	const service = useMemo(() => new LinkGeneratorService(), []);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [link, setLink] = useState<string>(service.getSingularLink('HOME'));

	const closeBanner = useCallback<SmartBannerModalContext['closeBanner']>(async () => {
		localStorage.setItem(SMART_BANNER_LOCALSTORAGE_KEY, '1');
		setIsOpen(false);
	}, [setIsOpen]);

	const generateLink = useCallback<SmartBannerModalContext['generateLink']>(
		async (type: keyof typeof LinkGeneratorService.LINK_TYPES, id?: string) => {
			const generatedLink = service.getSingularLink(type, id);

			setLink(generatedLink);
		},
		[setLink, service]
	);

	useEffect(() => {
		const isCloseFlag = localStorage.getItem(SMART_BANNER_LOCALSTORAGE_KEY);

		isCloseFlag === '1' ? setIsOpen(false) : setIsOpen(isMobile);
	}, []);

	return (
		<SmartBannerContext.Provider value={{ closeBanner, isOpen, link, generateLink }}>
			{children}
		</SmartBannerContext.Provider>
	);
};

export const useSmartBanner = () => {
	const context = useContext(SmartBannerContext);

	if (!context) {
		throw new Error('Cannot use ContentDetailsModalContext outside provider');
	}

	return context;
};
