import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React, { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material/styles';

export const RowLoader = ({ mobileThin }: { mobileThin?: boolean }) => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

	const height = useMemo(() => (isMobile && mobileThin ? 100 : 300), [isMobile, mobileThin]);
	const xsWidth = useMemo(() => (isMobile && mobileThin ? 12 : 9), [isMobile, mobileThin]);

	return (
		<Grid
			container
			spacing={3}
			paddingLeft={1}
			paddingRight={1}
			alignContent='center'
			justifyContent='center'
			paddingTop={3}
		>
			<Grid item xs={xsWidth} md={4} lg={3}>
				<Skeleton variant='rounded' height={height} />
			</Grid>
			{!isMobile && (
				<Grid item xs={xsWidth} md={4} lg={3}>
					<Skeleton variant='rounded' height={height} />
				</Grid>
			)}
			{!isMobile && (
				<Grid item xs={xsWidth} md={4} lg={3}>
					<Skeleton variant='rounded' height={height} />
				</Grid>
			)}
			{!isTablet && (
				<Grid item xs={xsWidth} md={4} lg={3}>
					<Skeleton variant='rounded' height={height} />
				</Grid>
			)}
		</Grid>
	);
};
